@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.App {
  font-family: 'Oswald', sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  color: #A9A9A9;
}

.nav {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  background-color: #457B9D;
  box-shadow: 0px 3px 21px -7px rgb(0 0 0 / 75%);
  border-bottom: 2px solid #E63946;
}

.nav-logo {
  margin-left: 50px;
}

.home-container {
  text-align: center;
  margin-top: 70px !important;
  color: #1D3557;
}

.pitch-container {
  text-align: center;
  margin-top: 50px;
}

.pitch {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 50px;
}

.pitch-background {
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 250px;
  transform: skew(15deg);
  background: #CAEBF2;
  margin-top: 20px;
  box-shadow: 4px 8px 0px 8px #9bbdc5;
}

.pitch img{
  width: 270px;
  border-radius: 150px;
  border: 10px solid #FF3B3F;
  /* box-shadow: 0px 5px 15px 2px rgba(12, 26, 19, 0.75); */
}

.pitch p{
  font-family: 'Oswald', sans-serif;
  color: #457B9D;
  font-size: 19px;
  width: 400px;
  margin-top: 50px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

.skills-container {
  text-align: center;
  margin-top: 120px;
}

.skills {
  text-align: center;
  color: #457B9D;
  background-color: #CAEBF2;
  border: 10px solid #9bbdc5;
}



.skill {
  margin: 30px;
  display: inline-block;
  
}

/* PROJECTS PAGE */

.projects-title {
  text-align: center;
  margin-top: 30px;
}

.projects-container {
  display: flex;
  
  width: 100%;
}

.projects-mapping {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.projects {
  margin: 10px;
  max-width: 270px;
}

.projects:hover {
  /* max-width: 300px; */
  -moz-transform: scale(1.3); /* for Firefox, default 1*/
    zoom:120%; /* For Chrome, IE, default 100%*/
}

.visit-btn {
  background: #FF3B3F !important;
  border: 1px solid #FF3B3F !important;
  width: 90%;

}

.close-btn {
  width: 90%;
}

/* FOOTER */

.footer-container {
  background: #457B9D;
  width: 100%;
  min-height: 80px;
  position: static;
  left:0px;
  bottom:0px;
  color: #1D3557;
}

.footer-connect {
  padding-top: 30px;
  padding-left: 50px;
}

.footer-connect ul {
  list-style-type: none
}

.footer-copyright {
  text-align: center;
  display: flex;
  justify-content: end;
  flex-direction: column;
}